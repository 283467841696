<template>
	<section :class="loading ? 'h-screen': 'h-auto'" class="page-bg relative">
		<div class="flex flex-col items-center justify-center lg:pt-32 md:pt-20 xsm:pt-20 font-russo">
			<div class="flex w-full items-center justify-center">
				<h1 v-motion-pop-visible class="relative top-0 w-fit h-auto py-4 justify-center flex bg-gradient-to-r items-center from-green-500 via-teal-500 to-green-500 bg-clip-text text-6xl font-extrabold text-transparent text-center select-auto">
					SEHAM STORE
				</h1>
			</div>
		</div>
		<div class="flex justify-center py-10">
			<div v-motion-pop-visible class="points-bg w-[200px] h-[115px] rounded-2xl">
				<div class="mt-12 text-center font-semibold text-sm text-[#0FB97F] uppercase">Seham Points: {{ points }}</div>
			</div>
		</div>
		<div class="flex flex-col justify-center items-center gap-4">
			<button v-if="$store.state.user != null" @click="initTransaction()" class="px-3 py-4 text-xs w-52 transition-all bg-[#0CC26A] border border-[#0CC26A] rounded-md shadow-2xl ease-in-out hover:scale-105 duration-200">
				<p class="tracking-widest text-sm font-bold uppercase text-white">Purchase History</p>
			</button>
			<form class="max-w-sm">
				<div class="relative">
					<select v-model="sortCategory" @change="sortByCategory" class="bg-[#212830] border-2 border-[#0CC26A] text-white rounded focus:ring-[#0CC26A] focus:border-[#0CC26A] block w-full py-2 pl-3 pr-12 appearance-none hover:border-[#0CC26A]">
						<option :value="null" disabled selected class="text-xs md:text-sm mr-3">Sort by Category</option>
						<option value="all" class="text-xs md:text-sm mr-3 text-white">All Products</option>
						<option value="followers" class="text-xs md:text-sm mr-3 text-white">For Kick Followers</option>
						<option value="affiliates" class="text-xs md:text-sm mr-3 text-white">For Affiliates</option>
					</select>
					<div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
						<svg class="w-4 h-4 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
							<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
						</svg>
					</div>
				</div>
			</form>
		</div>
		<div v-if="loading" class="flex justify-center py-14">
			<div class="w-24 h-24 rounded-full animate-spin
				border-y-8 border-solid border-green-600 border-t-transparent">
			</div>
		</div>
		<div v-if="loading == false && products.length == 0" class="text-center text-white text-2xl md:text-4xl md:py-24 font-bold">No Store Products yet..</div>
		<div v-if="loading == false && products.length > 0" class="container mx-auto p-4 flex justify-center items-center my-10 md:my-0">
			<div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-x-20 gap-y-14 md:gap-y-24 xl:my-54 lg:my-10 md:my-20">
				<div
				v-motion-pop-visible
				v-for="product in products"
				:key="product.id"
				class="bg-gradient-to-b from-[#17CE71] via-[#001C20] to-[#0EF581] p-3 shadow-md w-80 flex flex-col h-full"
				>
				<div class="p-6 flex flex-col bg-[#000606] rounded-sm items-stretch h-full">
					<img src="../assets/product-image.png" :alt="product.id" class="max-w-60 max-h-60 rounded-lg mb-4 mx-auto"/>
					<p class="text-[#05FF00] font-bold text-lg text-center mb-2 uppercase">
						{{ product.requiredPoints }} Points
					</p>
					<h2 class="text-lg font-semibold text-center text-white uppercase mb-2">
						{{ product.title }}
					</h2>
					<!-- <p class="text-white text-center text-sm mb-4">
						{{ product.description }}
					</p> -->
					<div class="flex justify-center mt-auto mb-4">
						<img src="../assets/product_asset.png" class="w-52" />
					</div>
					<button
					@click="viewProduct(product)"
					type="button"
					class="bg-transparent border border-[#0CC26A] text-[#0CC26A] font-semibold uppercase py-4 px-12 rounded-lg hover:text-white hover:bg-[#0CC26A] transition-all duration-300 mx-auto"
					>
						View Details
					</button>
				</div>
				</div>
			</div>
		</div>

		<div v-show="transactionDialog == true" class="relative z-50" aria-labelledby="modal-title" role="dialog" aria-modal="true"> 
			<div class="fixed inset-0 bg-black bg-opacity-70 transition-opacity" aria-hidden="true"></div>
			<div class="fixed inset-0 z-10 w-screen overflow-y-auto font-oxanium">
				<div class="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
					<div v-motion-pop-visible class="bg-[#151515] relative overflow-hidden rounded-lg text-left shadow-xl sm:my-8 sm:w-full sm:max-w-[1300px]">
						<div class="px-4 pb-6 pt-6 sm:p-6 sm:pb-4 md:mx-2">
							<div class="flex flex-col items-start gap-4 h-[400px] md:h-[600px] lg:h-[450px] overflow-y-auto" style="font-family: Poppins, system-ui;">
								<div v-if="transactionData.length == 0" class="w-full bg-black p-4">
									<div v-if="loadingPurchaseHistory == true" class="flex flex-row justify-between text-white">
										Loading purchase history...
									</div>
									<div v-else class="flex flex-row justify-between text-white">
										No purchase transaction history yet
									</div>
								</div>
								<div v-for="t in transactionData" :key="t" class="w-full bg-black p-4">
									<!-- Adjusted grid for better spacing -->
									<div class="md:grid hidden grid-cols-[3fr_3fr_0.5fr_1fr_1fr] gap-4">
										<div class="text-white truncate whitespace-nowrap">{{ t.ticketNumber }}</div>
										<div class="text-white truncate whitespace-nowrap">{{ t.product.title }}</div>
										<div class="text-white truncate whitespace-nowrap">{{ t.amount }}</div>
										<div class="uppercase truncate whitespace-nowrap" :class="statusClass(t.status)">{{ t.status }}</div>
										<div class="text-white truncate whitespace-nowrap">{{ formatDateTime2(t.createdAt) }}</div>
									</div>
									<div class="md:hidden grid grid-rows-5 gap-4">
										<div class="text-white truncate whitespace-nowrap">{{ t.ticketNumber }}</div>
										<div class="text-white truncate whitespace-nowrap">{{ t.product.title }}</div>
										<div class="text-white truncate whitespace-nowrap">{{ t.amount }}</div>
										<div class="uppercase truncate whitespace-nowrap" :class="statusClass(t.status)">{{ t.status }}</div>
										<div class="text-white truncate whitespace-nowrap">{{ formatDateTime2(t.createdAt) }}</div>
									</div>
								</div>
							</div>
						</div>
						<div class="px-4 py-4 md:flex md:flex-row md:justify-end md:px-10 md:mb-6 font-oxanium">
							<button @click="transactionDialog = false" type="button" class="inline-flex w-full justify-center rounded-md px-14 py-4 text-sm font-semibold hover:text-white hover:bg-[#0CC26A] shadow-lg sm:mt-0 sm:w-auto text-white bg-transparent border border-white hover:border-[#0CC26A] font-base relative uppercase main-btn">
								Close
							</button>
						</div>
					</div>  
				</div>
			</div>
		</div>
	</section>

</template>

<script>
import axios from "axios";
import moment from 'moment';
export default {
	name: "StorePage",
	components: {
		
	},
	data() {
		return {
			products: [],
			savedProducts: [],
			points: 0,
			loading: false,
			sortCategory: null,

			transactionDialog: false,
			transactionData: [],
			loadingPurchaseHistory: false
		};
	},

	methods: {
		async init() {
			const baseUrl = process.env.VUE_APP_BASE_API;
			const token = localStorage.getItem('seham-access-token');
			this.loading = true

			await axios
			.get(`${baseUrl}/api/store/products`, {
				headers: {
					Authorization: `Bearer ${token}`,
					"x-api-key": process.env.VUE_APP_X_API_KEY,
				},
			})
			.then((res) => {
				this.products = res.data.products
				this.savedProducts = res.data.products
			});

			if(this.$store.state.user != null){
				await axios
				.get(`${baseUrl}/api/user/points`, {
					headers: {
						Authorization: `Bearer ${token}`,
						"x-api-key": process.env.VUE_APP_X_API_KEY,
					},
				})
				.then((res) => {
					this.points = res.data.points
				});
			}
			else{
				this.points = '---'
			}
			
			this.loading = false
		},

		initTransaction(){
            this.transactionDialog = true
            this.loadingPurchaseHistory = true
            const token = localStorage.getItem('seham-access-token');
            axios.get(process.env.VUE_APP_BASE_API + '/api/store/purchase/transactions', {
                headers: {
                    'x-api-key': process.env.VUE_APP_X_API_KEY,
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then((res) => {
                if (res.data) {
                    this.transactionData = res.data.purchaseTransactions
                    this.loadingPurchaseHistory = false
                }
            }).catch((error) => {
                if (error.response) {
                    if (error.response.status === 401) {
                            localStorage.removeItem("vuex");
                            localStorage.removeItem("seham-access-token");
                            window.location.reload();
                    } else {
                        console.log(`Error: ${error.response.status}`);
                    }
                } 
                else if (error.request) {
                    console.log('No response received', error.request);
                } else {
                    console.log('Error', error.message);
                }
            });
        },
		statusClass(status) { //might change
            switch (status) {
                case 'inprogress':
                    return 'text-blue-600';
                case 'completed':
                    return 'text-green-600';
                case 'pending':
                    return 'text-yellow-600';
                case 'declined':
                    return 'text-red-600';
				case 'refunded':
                    return 'text-gray-600';
                default:
                    return '';
            }
        },
		sortByCategory() {
		if (!this.sortCategory) return;
			if(this.sortCategory == 'all'){
				this.products = this.savedProducts
			}
			else{
				this.products = this.savedProducts.filter(
					(item) => item.category === this.sortCategory
				);
			}
		},
		formatDateTime2(date, format = 'D MMM YYYY HH:mm') {
            if (date) {
                return moment(date).format(format);
            }
        },
        async viewProduct(data){
			this.$store.commit("UPDATE_PRODUCT", data);
			this.$router.push("/product-details");
		},
	},

	mounted() {
		this.init();
	},
  
};
</script>

<style scoped>
.page-bg {
	background-image: url("../assets/store-bg.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center top;
}
.points-bg {
	background-image: url("../assets/points_border.png");
	background-repeat: no-repeat;
	background-size: cover;
	box-shadow: 0 0 15px 5px rgba(0, 255, 0, 0.7); /* Green glow shadow */
}
.v-carousel__controls {
	bottom: -40px !important;
}
.font-rubik {
	font-family: "Rubik", sans-serif;
	font-optical-sizing: auto;
}
.font-russo {
	font-family: Russo One, sans-serif;
	font-optical-sizing: auto;
}
</style>
